import React, { createContext, FunctionComponent, useContext, useLayoutEffect, useState } from 'react';
import { theme } from '../styles/theme';
import Lion from '../static/images/background-lions.svg';

export const STORAGE_ITEM_KEY = 'KING-VAPE';
export const STORAGE_ITEM_VALUE = 'PERMITTED';

export interface ProtectedViewContext {
    auth: boolean;
    setAuth: (auth: boolean) => void;
}

export const ProtectedViewContext = createContext<ProtectedViewContext | null>(null);

export const ProvideProtectedViewContext = (): ProtectedViewContext => {
    const protectedViewContext = useContext(ProtectedViewContext);
    if (!protectedViewContext) {
        throw new Error('protectedViewContext must be used within a ProtectedViewProvider');
    }
    return protectedViewContext;
};

export const ProtectedViewProvider: FunctionComponent = ({ children }) => {
    const [auth, setAuth] = useState(false);

    useLayoutEffect(() => {
        const tempAuth = localStorage.getItem(STORAGE_ITEM_KEY) === STORAGE_ITEM_VALUE;
        setAuth(tempAuth);
        if (window) {
            const bodies = Array.from(document.getElementsByTagName('body'));
            bodies.forEach((body) => {
                body.style.backgroundColor = tempAuth ? theme.colors.lightGray : theme.colors.black;
                body.style.backgroundImage = tempAuth ? `url(${Lion})` : 'unset';
            });
        }
    });

    return <ProtectedViewContext.Provider value={{ auth, setAuth }}>{children}</ProtectedViewContext.Provider>;
};
