import React, { FunctionComponent, ReactNode } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

const theme = {
    colors: {
        white: '#FFFFFF',
        black: '#040606',
        yellow: '#FBC420',
        darkgray: '#545454',
        gray: '#DFE0DF',
        lightGray: '#F9F9F9',
    },
    effects: {
        shadow: '4px 4px 15px rgba(0, 0, 0, 0.25)',
    },
    borders: {
        radius: '10px',
        radiusSmall: '5px',
    },
    typography: {
        sizes: {
            label: 'calc(8px + (10 - 8) * ((100vw - 300px) / (1600 - 300)))',
            small: 'calc(12px + (14 - 12) * ((100vw - 300px) / (1600 - 300)))',
            normal: 'calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)))',
            large: 'calc(18px + (24 - 18) * ((100vw - 300px) / (1600 - 300)))',
            title: 'calc(24px + (36 - 24) * ((100vw - 300px) / (1600 - 300)))',
            enormous: 'calc(36px + (46 - 36) * ((100vw - 300px) / (1600 - 300)))',
        },
        weights: {
            lighter: 'lighter',
            normal: 'normal',
            bold: 'bold',
        },
    },
    mediaQueries: {
        mobile: '@media (max-width: 767px)',
        tablets: '@media (min-width: 768px) and (max-width: 1024px)',
        mobileAndTablets: '@media (max-width: 1024px)',
    },
};

interface Props {
    children: ReactNode;
}

const Theme: FunctionComponent<Props> = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export { Theme, theme };

export interface ThemedProps {
    theme: DefaultTheme;
}
